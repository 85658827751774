<template>
  <div v-if="item" class="item-chat grid gtc-auto-1 ggap-10 p-20">
    <!-- Left -->
    <a
      v-if="item?.user"
      :href="profileLink + item?.user?.id"
      class="item-chat__user-ava cover ava ava-30"
      v-tippy="item?.user?.fullname"
    >
      <img :src="item?.user?.avatar" />
    </a>

    <!-- Right -->
    <div class="grid ggap-5">
      <!-- User data -->
      <div class="item-chat__user-data flex ai-c jc-sb">
        <div class="flex ai-c ggap-10">
          <a :href="profileLink + (item?.user?.id || '')">{{ item?.user?.fullname }}</a>
          <small v-if="item?.user?.id === order?.author?.id" class="marker white-box">Инициатор</small>
        </div>
        <div class="flex ai-c ggap-10">
          <small class="fs-12 t-grey-dark">{{ formatRuDateTime(item?.created_at) }}</small>
          <template v-if="isHasEdit && isHasEditByStatus">
            <div v-if="item?.id === 'new-message'" class="item-chat__wait">
              <BaseLoad class="rel sm" />
            </div>
            <button
              v-else-if="item?.type !== 'system'"
              class="btn sm pl-10 pr-10"
              v-tippy="isEdit ? 'Отменить' : item?.comment ? 'Редактировать сообщение' : 'Добавить сообщение'"
              @click.prevent="isEdit = !isEdit"
            >
              <BaseIcon class="ic-16" :class="isEdit ? 'red' : 'primary'" :icon="isEdit ? 'close' : 'edit'" />
            </button>
          </template>
        </div>
      </div>

      <!-- Edit event message -->
      <form v-if="isEdit" class="box p-10 grid ggap-10 pos-r overflow-h" id="form-edit-message">
        <BaseLoad v-if="isLoad" class="grid jc-c z99999 bg white-transparent" />
        <FormEditor label="" placeholder="Сообщение к записи истории..." required v-model="formData.commentary" />
        <div class="flex ai-c jc-sb ggap-10">
          <button type="button" class="btn sm cancel" @click.stop.prevent="isEdit = false">Отмена</button>
          <button
            type="submit"
            class="btn sm primary"
            @click.stop.prevent="editAction"
            :disabled="!formData.commentary"
          >
            Отправить
          </button>
        </div>
      </form>

      <!-- Event data -->
      <div v-else class="box overflow-h">
        <!-- Message -->
        <section v-if="item?.comment" class="item-chat__section editor-data">
          <div v-if="item?.type === 'reject'" class="p-10 pb-0">
            <small class="marker red">Причина отклонения</small>
          </div>
          <div class="m-0 p-10" v-html="DOMPurify.sanitize(item?.comment)" />

          <!-- Old messages -->
          <template v-if="item?.before_changed && item?.before_changed.filter((el) => el.commentary)?.length">
            <!-- Sub message -->
            <div class="flex ai-c jc-sb ggap-10 p-10 pt-0">
              <small class="t-grey-dark fs-12">Изменено: {{ formatRuDateTime(item?.before_changed.updated_at) }}</small>
              <div class="flex ai-c ggap-5">
                <a href="#" class="self fs-12" @click.stop.prevent="isOpenChanges = !isOpenChanges">
                  История изменений
                </a>
                <small class="marker grey fs-12">
                  {{ item?.before_changed.filter((el) => el.commentary)?.length }}
                </small>
              </div>
            </div>

            <!-- Old messages -->
            <div v-if="isOpenChanges" class="item-chat__section-sub p-10 grid ggap-10">
              <div
                v-for="coment of item?.before_changed.filter((el) => el.commentary)"
                :key="coment ? coment.id : ''"
                class="box grid"
              >
                <div class="flex ai-c jc-sb ggap-10 p-10 pt-5 pb-5">
                  <span class="grid gtc-auto-1 ggap-10 ai-c">
                    <a
                      v-if="coment.user_edit_id"
                      :href="profileLink + coment.user_edit_id.id"
                      class="item-chat__user-ava cover ava ava-24"
                    >
                      <img :src="coment.user_edit_id.avatar || require('@/assets/img/ava.svg')" />
                    </a>
                    <a :href="profileLink + coment.user_edit_id.id">
                      {{ cutFullName(coment.user_edit_id.fullname) }}
                    </a>
                  </span>
                  <small class="fs-12 t-grey-dark">{{ formatRuDateTime(coment.updated_at) }}</small>
                </div>
                <hr class="m-0" />
                <div class="p-10 t-grey-dark" v-html="DOMPurify.sanitize(coment.commentary)" />
              </div>
            </div>
          </template>
        </section>

        <!-- Changes -->
        <section v-if="changeLength" class="item-chat__section grid">
          <h4 class="title p-10">Изменения:</h4>
          <ItemChanges v-for="change of changesRows" :key="change.key" :row="change" />
        </section>

        <!-- Docs -->
        <section v-if="item?.documents && item?.documents.length" class="item-chat__section grid">
          <ItemFile
            class="border-inside"
            v-for="file of item?.documents"
            :key="file.id"
            :item="file"
            :finalDocs="order.finalDocuments"
            :isSelectDisabled="isCantSelectDoc"
            :btns="{ download: isDownload(file.id), select: true, remove: false }"
            :orderStatus="order.status"
          />
        </section>

        <section v-if="allEmpty" class="item-chat__section p-10 t-red">Ничего не найдено</section>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { defineProps, computed, reactive, ref, toRefs, onMounted, inject } from 'vue'
import { BaseLoad, BaseIcon, FormEditor, ItemFile } from '@/components'
import { isInvalidForm, formatRuDateTime, cutFullName, linksBlank } from '@/plugins'
import ItemChanges from './ItemChanges.vue'
import events from '@/api/modules/events'
import DOMPurify from 'dompurify'

// Props
const props = defineProps(['item', 'order'])

// Data
const { item, order } = toRefs(props)
const profileLink = inject('profileLink')
const store = useStore()
const route = useRoute()
const isLoad = ref(false)
const isEdit = ref(false)
const isOpenChanges = ref(false)
const formData = reactive({
  commentary: item?.value?.comment
})

// Computed
const profile = computed(() => store.getters.profile)
const changeLength = computed(() => (item?.value?.changed_fields ? Object.keys(item?.value?.changed_fields).length : 0))
const allEmpty = computed(() => !item?.value?.comment && !changeLength.value && !item?.value?.documents?.length)
const isHasEdit = computed(() => item?.value.user?.id === profile.value?.id)
const isHasEditByStatus = computed(() => {
  switch (order.value?.status?.uid) {
    case 'canceled':
      return false

    case 'completed':
      return false

    case 'draft':
      return false

    case 'deleted':
      return false

    default:
      return true
  }
})
const isResponsible = computed(() => profile.value?.id === order.value?.responsible?.id)
const isSuperLawyer = computed(() => profile.value?.lawyer_role === 'Senior Lawyer')
const isLawyer = computed(() => profile.value?.lawyer_role === 'Lawyer')
const isCantSelectDoc = computed(() => {
  const res = []
  res.push(order.value.status.uid === 'in-watch')
  res.push(isResponsible.value || isSuperLawyer.value)
  return !res.every((el) => el === true)
})
const changesRows = computed(() => {
  const res = []
  for (const key in item?.value.changed_fields) {
    if (Object.hasOwnProperty.call(item?.value.changed_fields, key)) {
      const element = item?.value.changed_fields[key]

      if (typeof element === 'object' && (element.old || element.new || element.added || element.deleted)) {
        res.push({
          key,
          value: element
        })
      } else if (Array.isArray(element)) {
        res.push({
          key,
          value: element
        })
      }
    }
  }
  return res
})

// Mounted
onMounted(() => {
  linksBlank()
})

// Methods
function editAction() {
  if (isInvalidForm('form-edit-message')) return

  isLoad.value = true
  const cleanedMessage = formData.commentary.split('<p>&nbsp;</p>').join('')
  events
    .update(route.params.id, item?.value?.id, { commentary: cleanedMessage })
    .then(async () => {
      const payload = {
        orderId: route.params.id,
        eventId: item?.value?.id
      }
      await store.dispatch('events/GET_ITEM', payload)
      isEdit.value = false
    })
    .finally(() => (isLoad.value = false))
}
function isDownload(fileId) {
  const index = order.value.finalDocuments.findIndex((el) => el.id === fileId)
  if (index !== -1) return true
  else if (order.value?.status?.uid === 'completed') return isSuperLawyer.value || isLawyer.value
  else return true
}
</script>

<style lang="scss">
.item-chat {
  &__head {
    .cover {
      border: var(--b1);
    }
  }

  &__user {
    &-ava {
      position: sticky;
      top: 10px;
    }
    &-data {
      height: 30px;
    }
  }

  &__section {
    border-bottom: var(--b1);

    &:last-child {
      border: 0;
    }

    &-sub {
      background-color: var(--grey);
    }
  }
}
</style>
