<template>
  <div v-if="row && row.key" class="item-changes grid ggap-10 p-10">
    <small class="t-grey-dark fs-12">
      {{ fieldName }}
    </small>

    <!-- Objects -->
    <div v-if="row.key === 'responsible' || row.key === 'final_boss'" class="flex fw ggap-10">
      <span v-if="row.value.old" class="item-changes__box pos-r">
        <div class="item-changes__icon red" v-tippy="'Удалено'"><BaseIcon class="ic-14 white" icon="minus" /></div>
        <ItemUser
          isLink
          :isRemove="false"
          :item="{
            id: row.value.old.id,
            avatar: row.value.old.avatar,
            text: cutFullName(row.value.old.fullname),
            position: row.value.old.specialization || 'Без должности'
          }"
        />
      </span>
      <span v-if="row.value.new" class="item-changes__box pos-r">
        <div class="item-changes__icon green" v-tippy="'Добавлено'"><BaseIcon class="ic-14 white" icon="plus" /></div>
        <ItemUser
          isLink
          :isRemove="false"
          :item="{
            id: row.value.new.id,
            avatar: row.value.new.avatar,
            text: cutFullName(row.value.new.fullname),
            position: row.value.new.specialization || 'Без должности'
          }"
        />
      </span>
    </div>

    <!-- Statuses -->
    <div v-else-if="row.key === 'status'" class="flex ai-c ggap-10">
      <span>
        <small
          v-if="row.value.old"
          :style="{
            color: row.value.old.text_color,
            backgroundColor: row.value.old.background_color
          }"
          class="marker"
        >
          {{ row.value.old.name }}
        </small>
        <template v-else>-</template>
      </span>
      <span class="flex ai-c jc-c">
        <BaseIcon class="ic-20 black" icon="arrow-right" />
      </span>
      <span>
        <small
          v-if="row.value.new"
          :style="{
            color: row.value.new.text_color,
            backgroundColor: row.value.new.background_color
          }"
          class="marker"
        >
          {{ row.value.new.name }}
        </small>
        <template v-else>-</template>
      </span>
    </div>

    <!-- Dates -->
    <div
      v-else-if="row.key === 'got_for_work_at' || row.key === 'deadline'"
      class="grid ggap-10"
      :class="row.value.old ? 'item-changes__gtc' : ''"
    >
      <span v-if="row.value.old" class="flex fw ggap-10">
        {{ row.value.old ? formatDateTime(row.oldValue) : '-' }}
      </span>
      <span v-if="row.value.old" class="flex ai-c jc-c">
        <BaseIcon class="ic-20 black" icon="arrow-right" />
      </span>
      <span v-if="row.value.new" class="flex fw ggap-10">
        {{ row.value.new ? formatDateTime(row.value.new) : '-' }}
      </span>
    </div>

    <!-- Arrays -->
    <div
      v-else-if="row.key === 'watchers' || row.key === 'reviewers' || row.key === 'agreementers'"
      class="flex fw ggap-10"
    >
      <!-- Deleted -->
      <span v-for="user of row.value.deleted" :key="user.id" class="item-changes__box pos-r">
        <div class="item-changes__icon red" v-tippy="'Удалено'"><BaseIcon class="ic-14 white" icon="minus" /></div>
        <ItemUser
          isLink
          :isRemove="false"
          :item="{
            id: user.id,
            avatar: user.avatar,
            text: cutFullName(user.fullname),
            position: user.specialization || 'Без должности'
          }"
        />
      </span>

      <!-- Added -->
      <span v-for="user of row.value.added" :key="user.id" class="item-changes__box pos-r">
        <div class="item-changes__icon green" v-tippy="'Добавлено'"><BaseIcon class="ic-14 white" icon="plus" /></div>
        <ItemUser
          isLink
          :isRemove="false"
          :item="{
            id: user.id,
            avatar: user.avatar,
            text: cutFullName(user.fullname),
            position: user.specialization || 'Без должности'
          }"
        />
      </span>

      <!-- New -->
      <span v-for="user of row.value.new" :key="user.id" class="item-changes__box pos-r">
        <div class="item-changes__icon green" v-tippy="'Добавлено'"><BaseIcon class="ic-14 white" icon="plus" /></div>
        <ItemUser
          isLink
          :isRemove="false"
          :item="{
            id: user.id,
            avatar: user.avatar,
            text: cutFullName(user.fullname),
            position: user.specialization || 'Без должности'
          }"
        />
      </span>
    </div>

    <!-- Departments -->
    <div
      v-else-if="
        row.key === 'departments_agreementers' ||
        row.key === 'departments_reviewers' ||
        row.key === 'departments_watchers'
      "
      class="grid ggap-10"
    >
      <ItemDepartment
        v-for="depart of row.value"
        :key="depart.department ? depart.department.id : 'noname'"
        :item="depart"
      />
    </div>

    <!-- Documents -->
    <div
      v-else-if="row.key === 'documents' || row.key === 'final_documents'"
      class="grid ggap-10"
      :class="row.value.old ? 'item-changes__gtc' : ''"
    >
      <div v-if="row.value && row.value.deleted && row.value.deleted.length" class="box pos-r">
        <div class="item-changes__icon red" v-tippy="'Удалено'">
          <BaseIcon class="ic-14 white" icon="minus" />
        </div>
        <ItemFile
          class="border-inside"
          v-for="file of row.value.deleted"
          :key="file.id"
          :item="file"
          :btns="{ download: true, select: false, remove: false }"
        />
      </div>
      <div v-if="row.value && row.value.added && row.value.added.length" class="box pos-r">
        <div class="item-changes__icon green" v-tippy="'Добавлено'">
          <BaseIcon class="ic-14 white" icon="plus" />
        </div>
        <ItemFile
          class="border-inside"
          v-for="file of row.value.added"
          :key="file.id"
          :item="file"
          :btns="{ download: true, select: false, remove: false }"
        />
      </div>
    </div>

    <!-- Contacts -->
    <div
      v-else-if="row.key === 'contact_information'"
      class="grid ggap-10"
      :class="row.value.old ? 'item-changes__gtc' : ''"
    >
      <span v-if="row.value.old" class="flex fw ggap-10">
        <template v-if="row.value.old">
          <div v-for="(value, key) in row.value.old" :key="key" class="flex ai-c ggap-5 box p-5 pr-20 pos-r">
            <div class="item-changes__icon red" v-tippy="'Удалено'">
              <BaseIcon class="ic-14 white" icon="minus" />
            </div>
            <BaseIcon class="ic-16 primary" :icon="key" /> {{ value }}
          </div>
        </template>
        <template v-else> - </template>
      </span>
      <span v-if="row.value.old" class="flex ai-c jc-c">
        <BaseIcon class="ic-20 black" icon="arrow-right" />
      </span>
      <span class="flex fw ggap-10">
        <template v-if="row.value.new">
          <div v-for="(value, key) in row.value.new" :key="key" class="flex ai-c ggap-5 box p-5 pr-20 pos-r">
            <div class="item-changes__icon green" v-tippy="'Добавлено'">
              <BaseIcon class="ic-14 white" icon="plus" />
            </div>
            <BaseIcon class="ic-16 primary" :icon="key" /> {{ value }}
          </div>
        </template>
        <template v-else> - </template>
      </span>
    </div>

    <!-- Price -->
    <div
      v-else-if="row.key === 'annual_turnover' || row.key === 'contract_price'"
      class="grid ggap-10"
      :class="row.value.old ? 'item-changes__gtc' : ''"
    >
      <span v-if="row.value.old" class="flex fw ggap-10">
        {{ row.value.old ? Number(row.value.old).toLocaleString('ru-RU') : '-' }}
      </span>
      <span v-if="row.value.old" class="flex ai-c jc-c">
        <BaseIcon class="ic-20 black" icon="arrow-right" />
      </span>
      <span class="flex fw ggap-10">
        {{ row.value.new ? Number(row.value.new).toLocaleString('ru-RU') : '-' }}
      </span>
    </div>

    <!-- Comment & Special conditions -->
    <div v-else-if="row.key === 'comments' || row.key === 'special_conditions'" class="grid ggap-10">
      <span v-if="row.value.old" class="flex fw ggap-10 editor-data" v-html="DOMPurify.sanitize(row.value.old)" />
      <span v-if="row.value.old" class="flex ai-c jc-c">
        <BaseIcon class="ic-20 black" icon="arrow-down" />
      </span>
      <span v-if="row.value.new" class="flex fw ggap-10 editor-data" v-html="DOMPurify.sanitize(row.value.new)" />
    </div>

    <!-- Other -->
    <div v-else class="grid ggap-10" :class="row.value.old ? 'item-changes__gtc' : ''">
      <span v-if="row.value.old" class="flex fw ggap-10">
        {{ row.value.old ? row.value.old : '-' }}
      </span>
      <span v-if="row.value.old" class="flex ai-c jc-c">
        <BaseIcon class="ic-20 black" icon="arrow-right" />
      </span>
      <span class="flex fw ggap-10">
        {{ row.value.new ? row.value.new : '-' }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, toRefs } from 'vue'
import { BaseIcon, ItemUser, ItemFile } from '@/components'
import { formatDateTime, cutFullName } from '@/plugins'
import ItemDepartment from './ItemDepartment.vue'
import DOMPurify from 'dompurify'

// Props
const props = defineProps(['row'])

// Data
const { row } = toRefs(props)

// Computed
const fieldName = computed(() => {
  switch (row.value.key) {
    case 'got_for_work_at':
      return 'Взято в работу'

    case 'status':
      return 'Статус'

    case 'responsible':
      return 'Исполнитель'

    case 'agreementers':
      return 'Согласуют'

    case 'departments_agreementers':
      return 'Согласуют: отделы'

    case 'final_boss':
      return 'Финальный бос'

    case 'reviewers':
      return 'Рассматривают'

    case 'departments_reviewers':
      return 'Рассматривают: отделы'

    case 'watchers':
      return 'Подглядывают'

    case 'departments_watchers':
      return 'Подглядывают: отделы'

    case 'annual_turnover':
      return 'Оборот за 1 год'

    case 'contract_price':
      return 'Стоимость договора'

    case 'counterparty_id':
      return 'Контрагент'

    case 'counterparty_bin':
      return 'БИН контрагента'

    case 'counterparty_info':
      return 'Реквизиты контрагента'

    case 'counterparty_name':
      return 'Наименование контрагента'

    case 'contact_information':
      return 'Контактная информация'

    case 'documents':
      return 'Прикрепленные документы'

    case 'final_documents':
      return 'Финальные документы'

    case 'deadline':
      return 'Дата дедлайна'

    case 'special_conditions':
      return 'Особые условия'

    case 'comments':
      return 'Комментарий'

    default:
      return row.value.key
  }
})
</script>

<style lang="scss" scoped>
.item-changes {
  border-top: 1px var(--bcolor) dashed;

  &__icon {
    position: absolute;
    width: 16px;
    height: 16px;
    right: -3px;
    top: -3px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.green {
      background-color: var(--green);
    }
    &.red {
      background-color: var(--red);
    }
  }
  &__gtc {
    grid-template-columns: 1fr auto 1fr;
  }
}
</style>
